#notfound {

  #sorry {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #eee;
    overflow: hidden;
    padding: 0 0;

    h2 {
      color: #808080;
      font: normal bold 60px/1.25 Helvetica;
    }
  }

}
