#page-report {

  .list-group {

    .table > :not(caption) > * > * {
      padding: 1rem 0.5rem;
    }
    table {
      tr {
        cursor: pointer;
        th,
        td {
          font-size: 12px;
        }
      }
    }

    .list-title {
      font-size: 18px;
      font-weight: bold;
      margin: 0 0;
    }
    .list-id {
      margin-top: .75em;
      font-size: 12px;
      color: $secondary;
    }
    p {
      margin: 0 0;
    }
    .list-status {
      &.success {
        color: $success;
      }
      &.ongoing {
        color: $secondary;
      }
    }
    .list-person {}
    .list-date {}

  }

}

#appreciation-detail {
  > .container {
    @include media-breakpoint-down(xxl) {
      max-width: 100%;
    }
  }
}

#page-report-detail {
  /*
  padding-top: 48px;
  padding-left: 60px;
  padding-right: 60px;
  */

  .row {
    margin-top: calc(var(--bs-gutter-x) / -4);
    margin-bottom: calc(var(--bs-gutter-x) / -4);
    margin-left: calc(var(--bs-gutter-x) / -4);
    margin-right: calc(var(--bs-gutter-x) / -4);
    @include media-breakpoint-down(sm) {
      margin-top: calc(var(--bs-gutter-x) / -4);
      margin-bottom: calc(var(--bs-gutter-x) / -4);
      margin-left: calc(var(--bs-gutter-x) / -4);
      margin-right: calc(var(--bs-gutter-x) / -4);
    }
    > * {
      padding-top: calc(var(--bs-gutter-x) / 4);
      padding-bottom: calc(var(--bs-gutter-x) / 4);
      padding-left: calc(var(--bs-gutter-x) / 4);
      padding-right: calc(var(--bs-gutter-x) / 4);
      @include media-breakpoint-down(sm) {
        padding-top: calc(var(--bs-gutter-x) / 4);
        padding-bottom: calc(var(--bs-gutter-x) / 4);
        padding-left: calc(var(--bs-gutter-x) / 4);
        padding-right: calc(var(--bs-gutter-x) / 4);
      }
    }
  }

  #videos {
    position: relative;
    background-color: #151515;

    .loading {
      z-index: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      /*
      width: 60px;
      height: 60px;
      */
      content: "";
      display: block;
      .spinner-border {
        border-color: #404040;
        border-right-color: transparent;
      }
    }
    #video-screen {
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 2;
      width: 30%;
      border-radius: 6px;
      overflow: hidden;
      border: 1px solid rgba(255,255,255, .1);
    }
    #video-viewer {
      position: relative;
      z-index: 1;
      width: 100%;
      height: auto;
    }
    video {
      display: block;
    }
    #now {
      z-index: 3;
      position: absolute;
      top: 30px;
      left: 12 + 1px;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      text-align: right;
      // text-shadow: 0px 0px 6px #808080;
      width: 46px;

      background-color: #000;
      border-radius: 0 6px 6px 0;
      padding: 6px 12px;
      transition: $transition-duration;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -12px;
        margin-top: -6px;
        border: 6px solid transparent;
        border-right: 6px solid transparent;
        transition: $transition-duration;
      }

      /* Color */
      &.score-level-1 {
        background-color: $thermograph-2;
        &:before { border-right-color: $thermograph-2; }
      }
      &.score-level-2 {
        background-color: $thermograph-3;
        &:before { border-right-color: $thermograph-3; }
      }
      &.score-level-3 {
        background-color: $thermograph-4;
        &:before { border-right-color: $thermograph-4; }
      }
      &.score-level-4 {
        background-color: $thermograph-5;
        &:before { border-right-color: $thermograph-5; }
      }
    }

    .video-navigation {}
    #time {
      z-index: 3;
      position: absolute;
      bottom: 24px;
      right: 24px;
      color: #fff;
      font-size: 14px;
      font-weight: normal;
      line-height: 1;
      text-align: right;
    }

    #btn-playpause {
      z-index: 3;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      // show / hide
      transition: $transition-duration;
      opacity: 0;
      &:hover {
        opacity: 1;
      }

      &.load {
        opacity: 0;
      }

      $size: 6px;
      &:after {
        content: '';
        position: absolute;
        bottom: 24px;
        left: 24px;

        // play icon
        box-sizing: border-box;
        width: 0;
        height: $size * 2;
        border-style: solid;
        border-width: $size 0 $size $size * 1.618;
        border-color: transparent transparent transparent #fff;
        cursor: pointer;
        will-change: border-width;
        transition: all $transition-duration ease;
      }
      &.play {
        opacity: 1;
      }
      &.pause {
        &:after {
          // pause icon
          border-style: double;
          border-width: 0px 0 0px $size * 1.618;
        }
      }

    }
  }


  #data {

    .headline {
      color: #bfbfbf;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: .25em;
      text-transform: uppercase;
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-wrap: wrap;
      .report,
      .keycontrol {
        flex: 0 0 auto;
        width: 100%;
      }
      .report {
        order: 2;
      }
      .keycontrol {
        order: 1;
      }
    }

    .card {
      border-radius: 15px;
      border: 0;
      .card-body {
        padding: 30px;
      }
    }

    .infograph {
      .h {
        display: inline-flex;
        align-items: center;
        font-size: 11px;
        font-weight: bold;
      }
      .list {
        font-size: 10px;
        font-weight: bold;
        ul {
          padding: 0 0;
          margin: 0 0;
          // margin-left: 0.5rem;
          li {
            a {
              display: block;

              color: #0d0d0d;
              background-color: #e9e9e9;
              border: 1px solid #e9e9e9;
              padding: .25em .75em;
              border-radius: 1em;
              text-decoration: none;
              transition: $transition-duration;
              &:hover {
                color: #0d0d0d;
                background-color: #cccccc;
                border: 1px solid #cccccc;
              }
            }
          }
        }
      }
      .max {
        svg {
          color: mix(orange,red);
        }
      }
      .min {
        svg {
          color: blue;
        }
      }
    }

  }


  .score {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    background-color: #ccc;
    background: linear-gradient(to right, $thermograph-4, $thermograph-5);
    padding: 5px;
    > div {
      width: 72px;
      height: 72px;
      padding-bottom: 3px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      color: red;
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      small {
        font-size: 10px;
        // font-weight: normal;
        transform: scale(0.8,0.8);
        // letter-spacing: .2em;
        margin-left: .1em;
      }
    }
  }


  /*
  .progress {
    display: none;
    .progress-bar {
      font-size: 10px;
      font-weight: bold;
      border-radius: 0.25rem;
      &.bg-hot {
        background-color: #ccc;
        background: linear-gradient(to right, $thermograph-4, $thermograph-5);
      }
      &.bg-cool {
        background-color: #ccc;
        // background: linear-gradient(to right, blue, purple);
        background: linear-gradient(to right, $thermograph-2, $thermograph-1);
      }
    }
  }
  */


  .keycontrol {
    table {
      td {
        text-align: center;
        font-size: 12px;
        color: #808080;
        kbd {
          display: block;
          background-color: transparent;
          border: 1px solid #808080;
          color: #808080;
          cursor: pointer;
        }
      }
    }
  }

  /* Chart + Timeline */
  $chart_spacer_left: 6px;
  $chart_spacer_right: 26px;

  canvas{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .canvas-container {
    position: relative;
    margin-left: $chart_spacer_left * -1;
    margin-right: $chart_spacer_right * -1;
    > div {
      // width: calc(100% + 36px);
    }
    #canvas {
      width: calc(100% + 36px);
    }
  }


  #progress-wrap {
    width: 100%;
    height: 50 + 3px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-left: $chart_spacer_left;
    padding-right: $chart_spacer_right;
    // mix-blend-mode: screen;
    #progress-out {
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0, 0);
      transform: scaleY(1);
      transition: 0.3s;
      &:hover {
        cursor: pointer;
      }

      #progress-in {
        margin: 0 auto 0 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, 0);
        transform-origin: top left;
        transition: 0.1s;
        border-top: 3px solid $primary;
        border-right: 1px solid $primary;
      }
    }
  }


}
