/*
 * Object Fit
 */

.objectfit {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  &._contain {
    object-fit: contain;
  }
}


/*
 * Scrollbar
 */
.scrollbar-x-hide {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  text-align: left;
  display: flex;
  align-items: center;

  // scrollbar hidden
  -ms-overflow-style: none; // IE, Edge
  scrollbar-width: none; // Firefox
  // Chrome, Safari
  &::-webkit-scrollbar {
    display: none;
  }
}
