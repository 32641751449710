.row {
  margin-top: calc(var(--bs-gutter-x) / -2);
  margin-bottom: calc(var(--bs-gutter-x) / -2);
  @include media-breakpoint-down(sm) {
    margin-top: calc(var(--bs-gutter-x) / -4);
    margin-bottom: calc(var(--bs-gutter-x) / -4);

    margin-left: calc(var(--bs-gutter-x) / -4);
    margin-right: calc(var(--bs-gutter-x) / -4);
  }
  > * {
    padding-top: calc(var(--bs-gutter-x) / 2);
    padding-bottom: calc(var(--bs-gutter-x) / 2);
    @include media-breakpoint-down(sm) {
      padding-top: calc(var(--bs-gutter-x) / 4);
      padding-bottom: calc(var(--bs-gutter-x) / 4);

      padding-left: calc(var(--bs-gutter-x) / 4);
      padding-right: calc(var(--bs-gutter-x) / 4);
    }
  }
}

* {
  // cursor:url("../img/cursor.png"), auto;
  // cursor:-webkit-image-set(url("../img/cursor.png") 1x, url("../img/cursor.png") 2x) 0 0,pointer !important
}

::selection,
::-moz-selection {
//  color: #fff;
  background: $primary;
  background-color: rgba($primary,0.5);
}



.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}
