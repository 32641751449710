/*
 * :root
 */
:root {
}

/*
 * color
 */

/* Add Colors */
$neutral: #000;

/* bootstrap: Update $theme-colors */
$primary: #1a73e8;

/* bootstrap: Add $theme-colors */
$primary-100: #BFB28C;
$primary-300: #80775D;
$primary-500: $primary;
$primary-700: #595341;
$primary-900: #333025;

/* bootstrap: Add $theme-colors */
$thermograph-1: purple;
$thermograph-2: blue;
$thermograph-3: green;
$thermograph-4: orange;
$thermograph-5: red;


$custom-colors: (
  "neutral": $neutral,
  'primary-100': #BFB28C,
  'primary-300': #80775D,
  'primary-500': $primary,
  'primary-700': #595341,
  'primary-900': #333025,

  'thermograph-1': $thermograph-1,
  'thermograph-2': $thermograph-2,
  'thermograph-3': $thermograph-3,
  'thermograph-4': $thermograph-4,
  'thermograph-5': $thermograph-5,
);

$transition-duration: .3s;

$font-family-base: "HelveticaNeue","Helvetica",sans-serif;

/*
 * Space
 */
$grid-gutter-width: 60px;
