#wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;

  main {
    article {
      padding: 48px 0;
    }
  }

  footer {
    margin-top: auto;
  }
}
