@import "variables";
@import "mixin";

// @import "~bootstrap/scss/bootstrap-grid.scss";
@import "~bootstrap/scss/bootstrap.scss";

/*map-merge で  $theme-colors に $custom-colors をマージ*/
$theme-colors: map-merge($theme-colors, $custom-colors);


// @import "reboot";
@import "layout";
@import "content";
@import "forms";
@import "components";
@import "helpers";
@import "utilities";
@import "extened";

@import "customize";
@import "pages";
