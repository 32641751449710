@import "components/header";
@import "components/footer";

.feather {
  margin-right: 4px;
}

#page-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: .5em;
}


.score-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background-color: #ccc;
  // background: linear-gradient(to right, $thermograph-5, $thermograph-4);
  // padding: 5px;
  transition: $transition-duration;
  > div {
    width: 72px;
    height: 72px;
    // padding-bottom: 3px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    // color: $thermograph-5;
    color: #ccc;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    transition: $transition-duration;
    small {
      font-size: 10px;
      // font-weight: normal;
      transform: scale(0.8,0.8);
      // letter-spacing: .2em;
      margin-left: .1em;
    }
  }

  &.small {
    width: 84 * .5px;
    height: 84 * .5px;
    // padding: 5px;
    > div {
      width: 72 * .5px;
      height: 72 * .5px;
      // padding-bottom: 3px;
      font-size: 30 * .5px;
    }
  }

  /* Color */
  &.score-level-1 {
    background: linear-gradient(135deg, $thermograph-2, $thermograph-1);
    > div { color: $thermograph-2; }
  }
  &.score-level-2 {
    background: linear-gradient(135deg, $thermograph-3, $thermograph-2);
    > div { color: $thermograph-3; }
  }
  &.score-level-3 {
    background: linear-gradient(135deg, $thermograph-4, $thermograph-3);
    > div { color: $thermograph-4; }
  }
  &.score-level-4 {
    background: linear-gradient(135deg, $thermograph-5, $thermograph-4);
    > div { color: $thermograph-5; }
  }

}
