/*
 * Ratio
 */

[class*="ratio-"] {
  position: relative;
  width: 100%;

  &:before {
    display: block;
    padding-top: var(--aspect-ratio,calc(9 / 16 * 100%));
    content: "";
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

$aspect-ratios: (
  "1x1": 100%,
  "2x1": 50%,
    "1x2": 150%,
  "3x2": calc(2 / 3 * 100%),
    "2x3": calc(3 / 2 * 100%),
  "4x3": calc(3 / 4 * 100%),
    "3x4": calc(4 / 3 * 100%),
  "16x9": calc(9 / 16 * 100%),
    "9x16": calc(16 / 9 * 100%),
  "golden": calc(1 / 1.618 * 1%),
  "silver": calc(1 / 1.414 * 1%),
);

@each $key, $ratio in $aspect-ratios {
  .ratio-#{$key} {
    --aspect-ratio: #{$ratio};
  }
}


/*
 * Scroll: IntersectionObserver
 */
.obs {
  opacity: 0;
  transition: .3 * 2;
  &.--in {
    opacity: 1;
  }
}
