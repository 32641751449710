/*
 * Swiper
 */
// $themeColor: #000;
// @import "~swiper/swiper.scss";
@import "~swiper/swiper-bundle.css";

:root {
  --swiper-theme-color: #000;
  --swiper-navigation-size: 24px;
}

.swiper-fullscreen {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .swiper-container {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}

@include media-breakpoint-down(sm) {
  .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 2.5px;
  }
}
